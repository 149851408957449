import React from 'react'

const AgentList = React.lazy(() => import('../pages/Agents/AgentList/AgentList'))
const AgentDetail = React.lazy(() => import('../pages/Agents/AgentDetail/AgentDetail'))
const AgentDetailPost = React.lazy(() => import('../pages/Agents/AgentDetailPost/AgentDetailPost'))
const CreateAgent = React.lazy(() => import('../pages/Agents/CreateAgent/CreateAgent'))
const EditAgent = React.lazy(() => import('../pages/Agents/EditAgent/EditAgent'))
const CreateAgentRegion = React.lazy(() => import('../pages/Agents/CreateRegion/CreateRegion'))
const EditAgentRegion = React.lazy(() => import('../pages/Agents/EditRegion/EditRegion'))
const CreateAgentProduct = React.lazy(() => import('../pages/Agents/CreateProduct/CreateProduct'))
const EditAgentProduct = React.lazy(() => import('../pages/Agents/EditProduct/EditProduct'))
const GenerateAgentReport = React.lazy(() =>
  import('../pages/Agents/GenerateAgentReport/GenerateAgentReport'),
)
const AgentBonusStock = React.lazy(() =>
  import('../pages/Agents/AgentBonusStock/AgentBonusStock.jsx'),
)

export {
  AgentList,
  AgentDetail,
  AgentDetailPost,
  CreateAgent,
  EditAgent,
  CreateAgentRegion,
  EditAgentRegion,
  CreateAgentProduct,
  EditAgentProduct,
  GenerateAgentReport,
  AgentBonusStock,
}
